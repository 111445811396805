import React from "react"
import Helmet from "react-helmet"
import Layout, { Section, H2 } from "../../components/layout"
import Hero from "../../components/hero"
import Footer from "../../components/footer"
import backgroundImage from '../../images/articles/what-to-expect-2023.jpg';
import styled from "styled-components"

const ArticleInfo = styled.div`
    display: flex;
`

const DatePosted = styled.p`
    font-weight: bold;
`

const ReadTime = styled.p`
    margin-left: 1rem;
`

const IndexPage = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Polis Legal - What to expect in 2023</title>
                <meta name="description" content="2023 is a year of somewhat stabilising the new 'normal' for how we live, work and appreciate our spaces. Here's a snapshot of some key developments which can be expected for this year." />
                <meta name="robots" content="index, follow" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="What to expect in 2023" />
                <meta property="og:url" content="//polislegal.com.au/articles/what-to-expect-in-2023/" />
                <meta property="og:image" content={`//polislegal.com.au${backgroundImage}`} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="757" />
                <meta property="og:image:alt" content />
                <meta property="og:locale" content="en_GB" />
                <meta property="article:published_time" content="2023-01-27T09:00:00+00:00" />
            </Helmet>
            <Hero background={backgroundImage} title="What to expect in 2023" />
            <Section>
                <ArticleInfo>
                    <DatePosted>27 January 2023</DatePosted>
                    <ReadTime>6 min read</ReadTime>
                </ArticleInfo>

                <p>The past few years introduced a range of activity in the planning and environment sector to boost property development and economic recovery following the pandemic. 2022 also brought us federal and state elections with various implications. We have new acronyms to get used to, in particular the newly configured Department of Transport and Planning (<strong>DTP</strong>) and Department of Energy, Environment and Climate Action (<strong>DEECA</strong>), and a new Planning Minister with a bulging in-tray. We wait to see if these reconfigured departments and roles result in any change to the way planning, infrastructure, environment and economic considerations are integrated through various projects.</p>
                <p>Set out below is a snapshot of five key developments which can be expected for 2023, being a year of somewhat stabilising the new 'normal' for how we live, work and appreciate our spaces.</p>
                <H2>Bringing a new energy to 2023</H2>
                <p>With the return of the Labour government, a new body within DTP, VicGrid, will be established. Amongst other things, VicGrid will continue planning for Victoria's identified Renewable Energy Zones, including the much-needed investment into electricity transmission and renewable energy generation infrastructure across Victoria.</p> 
                <p>Onshore renewable energy projects (including wind and solar farms) will continue their progression in order to achieve our targets of 40% renewable energy by 2025 and 50% by 2030. New targets for energy storage (eg, batteries) are expected to be legislated in 2023.</p>
                <p>The offshore wind sector will continue to evolve this year, with further guidance and policy to be released by all levels of government. This will lead to increasing overlap between State and federal regimes. Feasibility licences will be sought in 2023 by offshore wind proponents from the federal government, while scoping requirements and assessments continue in order to progress Environment Effects Statements (<strong>EES</strong>) at State level. With so many energy projects in the pipeline, perhaps we will also finally see an update to the Environment Effects Act 1978, the EES Guidelines, and a new process generally for dealing with the increasing scale of these new projects in a way that avoids lengthy and costly procedures while ensuring appropriate and effective engagement and assessment.</p>
                <H2>Housing changes</H2>
                <p>2023 follows emerging trends of where people want to live, following the pandemic lockdowns and an evolution to more hybrid styles of working. This includes increasing housing demand in regional Victoria and a scurry to finalise lots in the growth areas following the boom in sales over the past few years. With an expected increase in migration and population following the period of downturn during the pandemic, there is also a need to ensure that supply keeps up with demand in order to avoid further affordability issues.</p>
                <p>A number of new initiatives are underway this year to facilitate more streamlined approvals processes to maintain housing supply:</p>
                <ul>
                    <li><p>The industry awaits the ResCode reforms initiated by DTP (formerly DELWP) to improve the assessment model for certain developments</p></li>
                    <li><p>The Future Homes pilot program will expand its application to other municipalities (currently in the City of Maribyrnong) and the uptake of this new design model remains to be seen </p></li>
                    <li><p>The list of significant redevelopment projects which have been included in the Victorian Planning Authority's (<strong>VPA</strong>) Priority Program, assessed via the VPA Projects Standing Advisory Committee, has included the Arden Precinct, Preston Market and various metropolitan and regional Precinct Structure Plans, and ongoing work by the VPA on priority projects is expected to continue </p></li>
                    <li><p>Another round of reviews to the Better Apartment Design Standards (<strong>BADS</strong>) is expected, following the inquiry of the Legislative Assembly's Environment and Planning Committee into apartment design standards. The inquiry's 2022 report made a number of recommendations, including a review and update of the BADS regarding matters such as daylight, private and communal open spaces, layout and sizing and design review processes </p></li>
                    <li><p>Although the Victorian government confirmed in 2022 that it was not proceeding with its Social and Affordable Housing Contribution levy on new developments, the Big Housing Build continues, together with increasing work by the private sector and local councils to contribute to alleviating Victoria's housing affordability crisis for very low-, low- and moderate-income earners, including through housing models such as Build-to-Rent and Build-to-Rent-to-Buy</p></li>
                </ul>
                <p>Getting involved and making submissions on new initiatives will be important to ensure that the decision makers on these legislative and policy changes consider all relevant issues.</p>
                <H2>A resilient 2023</H2>
                <p>There is no debate that we will continue to face ongoing climate change and extreme weather events, and planning for resilient development needs to be a priority for those areas most affected by bushfires, floods, storm and sea level rise and changing atmospheric and ecological conditions.</p>
                <p>We may see more forward-thinking strategic amendments proposed in order to address these issues that are here to stay. These include the ongoing work under the Marine and Coastal Strategy 2022 and changes following recent consultation to enhance protections for Victoria's important agricultural areas and Green Wedge land. </p>
                <p>An increased focus on sustainability and adaptability in design, and the use of energy efficient practices and durable building materials, will ensure that future developments are more resilient to these ongoing risks and challenges. </p>
                <p>Ongoing consultation into federal legislative reforms is also expected following the Samuel review of the EPBC Act 1999, including new National Environmental Standards, a new independent national Environment Protection Authority, and overhaul of the offsetting regime with a new Nature Repair Market, all of which the Victorian government and industry will need to adapt to. </p>
                <H2>New year, new reforms</H2>
                <p>A plethora of reforms to the planning system are expected to be announced, which the industry will need to be ready for. At the State level alone we can expect:</p>
                <ul>
                    <li><p>The potential for the Development Facilitation Program (<strong>DFP</strong>) to be extended; while the program was slated to end in December 2022, time will tell if further announcements are made about this</p></li>
                    <li><p>The ongoing 'fast-tracking' of significant projects, including projects from the DFP and the VPA Priority Projects program, which are invariably assessed via Standing Advisory Committees with s20(4) planning scheme amendments (which has both benefits and dis-benefits) </p></li>
                    <li><p>There is still a lot more to be done following the Cronin Red Tape review of the planning system, with the Victorian government committed to acting on all of the Review's recommendations. Consultation on further VPP changes can be expected in 2023</p></li>
                    <li><p>As above, a refresh of the EES Guidelines is anticipated, hopefully together with reforms on how the increasing number of EESs can be efficiently and effectively managed</p></li>
                    <li><p>Reform into development contributions; the Victorian government has not yet released the report and recommendations of the 2021 Infrastructure Contributions Advisory Committee, and the VPA's work into implementing Infrastructure Contributions Plans into regional areas is currently on hold </p></li>
                    <li><p>the Windfall Gains Tax will commence on 1 July 2023, with significant levies payable upon most rezonings that increase the value of land </p></li>
                </ul>
                <H2>2023 - don't waste it</H2>
                <p>Following the introduction of the circular economy plan and the establishment of Recycling Victoria, new initiatives will be introduced in 2023 that will not only effect the industry as a profession, but all of us in our everyday lives: </p>
                <ul>
                    <li><p>The single-use plastics ban commences on 1 February 2023. Businesses and organisations should consider offering reusable items or single-use items made from alternative materials. Individuals should ensure to bring your keep cup and glass/metal bowl or container for grabbing your meal on the go! </p></li>
                    <li><p>The Victorian container deposit scheme will commence in 2023, similar to the model in other States whereby a 10-cent refund is given for returned cans, cartons and bottles </p></li>
                    <li><p>A 4-bin waste system for all Victorian households will progress (new bin for glass) </p></li>
                    <li><p>Greater regulation and innovation into how soft plastics are managed and recycled can be expected following the recent REDcycle collapse </p></li>
                </ul>
                <p>At the very least, the way in which waste is stored, managed and transported for all land uses and facilities will need significant overhauls to keep apace with these ongoing initiatives. </p>
                <p>It's going to be a busy year! </p>
            </Section>
            <Footer />
        </Layout>
    )
}

export default IndexPage
